import { State, TabDisplayInterface, User, UserRole, UserType } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { switchMap, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    const isPlaylistAdmin = (roles: UserRole[]) => {
      return (
        roles.includes(UserRole.INTERNAL_ADMIN) ||
        roles.includes(UserRole.CURRICULUM_ADMIN) ||
        roles.includes(UserRole.ACCOUNT_ADMIN)
      );
    };

    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      switchMap((user: User) => {
        if (!user) {
          return of(false);
        }
        switch (user.type) {
          case UserType.STUDENT:
            return of(true);
          case UserType.TEACHER:
            return of(true);
          case UserType.PARENT:
          case UserType.UPSTART:
            return of(true);
          case UserType.ADMIN:
            return of(isPlaylistAdmin(user.roles));
          default:
            return of(false);
        }
      }),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
